import React from "react"
import { Link } from "gatsby"
import cx from "../../images/cover/cx.png"
import l2f from "../../images/cover/l2f.png"
import iser from "../../images/cover/iser.png"
import frostbyte from "../../images/cover/frostbyte.png"
import Section from "../global/Section"

export default function Work() {
  return (
    <Section container="container-fluid">
      <div className="row">
         <div className="col-md-3 col-lg-3 col-sm-12 teamprofile pb-2 ">
          <Link to="/l2f" className="w text-reset text-decoration-none">
            <div className="card-profile">
              <img src={l2f} className="mx-auto d-block" width="100%" />
              <div className="card-body">
                <h5 className=" font-w-bold">Layer2.Finance</h5>
                <p className="subtitle content-small pb-2">
                 An investment solution for DeFi protocols.
                </p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-3 col-lg- col-sm-12 teamprofile pb-2">
          <div className="card-profile">
            <Link to="/cx" className="w text-reset text-decoration-none">
              <img src={cx} className="mx-auto d-block" width="100%" />
              <div className="card-body">
                <h5 className=" font-w-bold">CelerX</h5>
                <p className="subtitle content-small pb-2">
                  CelerX is an online mobile eSports platform.
                </p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-3 col-lg-3 col-sm-12  teamprofile pb-2">
          <Link to="/iser" className="w text-reset text-decoration-none">
            <div className="card-profile">
              <img
                src={iser}
                className="mx-auto d-block"
                width="100%"
                alt="iser"
              />
              <div className="card-body">
                <h5 className=" font-w-bold">ISER</h5>
                <p className="subtitle content-small pb-2">
                  An internal web tool for server management.
                </p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-3 col-lg-3 col-sm-12 teamprofile pb-2 ">
          <Link to="/frostbyte" className="w text-reset text-decoration-none">
            <div className="card-profile">
              <img src={frostbyte} className="mx-auto d-block" width="100%" />
              <div className="card-body">
                <h5 className=" font-w-bold">FrostByte</h5>
                <p className="subtitle content-small pb-2">
                  Cold chain monitoring tool.
                </p>
              </div>
            </div>
          </Link>
        </div>
        
      </div>
    </Section>
  )
}
