import React from "react"
import Section from "../global/Section"

export default function Hero() {
  return (
    <Section container="container" extraMargin={"8rem"}>
      <div className="row">
        <div className="col-sm-12">
          <h2 className="font-w-bold">
            Hi, I'm Tai 😎 <br />
            Welcome to my site 🌴
          </h2>
          <p className="l-grey pt-3">
            I share some of my works and ideas here.
          </p>
        </div>
      </div>
    </Section>
  )
}
